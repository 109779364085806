<template>
  <div
    class="w-full overflow-x-auto rounded-t-lg scrollbarHide shadow flex-1 bg-white tabletLandscape:max-h-[93vh]"
    :class="{ 'pb-[70px]': applyPadding, 'sticky top-0': stickyTable }"
  >
    <table
      class="min-w-[900px] w-full h-[1px] order-collapse table-fixed text-left relative"
      :class="{ '!border-separate !border-spacing-y-2': borderSpacing }"
    >
      <colgroup>
        <col
          v-for="(col, index) in columns"
          :key="index"
          :style="{ width: col.width + 'px' }"
        />
      </colgroup>
      <thead
        class="bg-neutral-50 border-b border-neutral-80 rounded-t-lg"
        :class="{ '!bg-neutral-80': darkGray }"
      >
        <tr>
          <th
            v-for="(col, index) in columns"
            :key="index"
            :colspan="col.colspan || 1"
            class="bg-neutral-50 sticky top-0 z-[2] first:rounded-tl-lg last:rounded-tr-lg !p-4 !overflow-hidden !whitespace-nowrap"
            :class="{ 'bg-neutral-80': darkGray }"
          >
            <CheckboxBtn
              v-if="col.field === 'checkbox'"
              data-cy="select_all"
              :is-active="isAllChecked"
              class="!mr-0 mt-1"
              @click="emit('check')"
            />
            <div class="flex flex-col gap-1">
              <TextTiny v-if="col.doubleLabel" blue class="min-h-[12px]">
                {{ col.doubleLabel === 'empty' ? '' : col.doubleLabel }}
              </TextTiny>
              <TextTiny neutral bold class="uppercase select-none">
                {{ col.label }}
              </TextTiny>
            </div>
            <div
              v-if="index < columns.length - 1"
              :data-index="index"
              class="resizer absolute top-0 right-0 pl-2 h-full cursor-col-resize bg-neutral-50 flex items-center"
              :class="{ '!bg-neutral-80': darkGray }"
              @mousedown="startResizing($event, col, index)"
            >
              <div
                class="bg-neutral-80 w-0.5 h-[45%]"
                :class="{ '!bg-neutral-50': darkGray }"
              ></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'select-none': isResizing }">
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextTiny from '@/components/shared/font/text/TextTiny'
import { ref } from 'vue'

const emit = defineEmits(['check'])
const props = defineProps({
  columns: Array,
  darkGray: Boolean,
  borderSpacing: Boolean,
  stickyTable: Boolean,
  applyPadding: {
    type: Boolean,
    default: true
  },
  isAllChecked: Boolean
})

const isResizing = ref(false)

function startResizing(event, col, index) {
  const wColSpan = props.columns.some((c) => c.colspan)
  const startX = event.clientX
  const startWidth = col.width
  const nextCol = props.columns[index + 1] || null
  const nextColWidth = props.columns[index + 1]?.width || null
  const minWidth = 30

  let affectedIndexes = [index]
  let totalSpanWidth = col.width

  if (col.colspan && col.colspan > 1) {
    for (let i = index + 1; i < index + col.colspan; i++) {
      affectedIndexes.push(i)
      totalSpanWidth += props.columns[i].width
    }
  }

  const doResize = (moveEvent) => {
    isResizing.value = true
    const deltaX = moveEvent.clientX - startX
    const newWidth = startWidth + deltaX

    if (wColSpan) {
      if (col.colspan && col.colspan > 1) {
        let newTotalWidth = Math.max(
          totalSpanWidth + deltaX,
          minWidth * affectedIndexes.length
        )
        let newColWidth = newTotalWidth / affectedIndexes.length
        affectedIndexes.forEach((i) => {
          props.columns[i].width = newColWidth
        })
      } else nextCol.width = Math.max(startWidth + deltaX, minWidth)
    } else {
      if (newWidth >= minWidth) col.width = newWidth
      else col.width = minWidth

      if (nextColWidth !== null && newWidth >= minWidth) {
        const newNextWidth = nextColWidth - deltaX
        if (newNextWidth >= minWidth)
          props.columns[index + 1].width = newNextWidth
        else props.columns[index + 1].width = minWidth
      }
    }
  }

  const stopResizing = () => {
    window.removeEventListener('mousemove', doResize)
    window.removeEventListener('mouseup', stopResizing)
    isResizing.value = false
  }

  window.addEventListener('mousemove', doResize)
  window.addEventListener('mouseup', stopResizing)
}
</script>
