<template>
  <div class="relative">
    <textarea
      :id="id"
      :name="id"
      class="block !rounded-lg outline-none resize-y border p-2.5 w-full text-neutral-200 placeholder:text-neutral-100 text-sm bg-neutral-50 border-neutral-80 focus:ring-0 focus:border-primary-100 focus-visible:outline-none"
      :class="{
        '!pr-8': $slots.suffix,
        'min-h-[43px] h-[43px]': size === 'maxContent',
        'min-h-[100px]': size === 'sm',
        'min-h-[200px]': size === 'md',
        'resize-y': vertical,
        'cursor-not-allowed': disabled,
        '!bg-white': white,
        '!border-error-100': error,
        '!border-warning-200': warning,
        rtl,
        '!cursor-default !border-none': noArea,
        '!resize-none': noResize
      }"
      :value="value"
      :placeholder="placeholder ? `${placeholder}${indicator}` : ''"
      :maxlength="max"
      :disabled="disabled"
      @input="emit('inputChange', $event.target.value)"
      @blur.prevent="emit('onBlur')"
    />
    <div class="absolute top-1/2 -translate-y-1/2 right-3">
      <slot name="suffix" />
    </div>
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import { computed } from 'vue'

const $t = i18n.t

const emit = defineEmits(['inputChange', 'onBlur'])
const props = defineProps({
  id: String,
  value: String,
  placeholder: String,
  max: Number,
  vertical: Boolean,
  disabled: Boolean,
  required: Boolean,
  optional: Boolean,
  white: Boolean,
  error: Boolean,
  warning: Boolean,
  rtl: Boolean,
  noArea: Boolean,
  noResize: Boolean,
  size: {
    type: String,
    default: 'default',
    validator(value, props) {
      return ['default', 'sm', 'md', 'maxContent'].includes(value)
    }
  }
})

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})
</script>
