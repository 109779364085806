<template>
  <td
    class="tableCell !relative overflow-visible"
    :class="{ '!p-4': applyPadding }"
    :colspan="colspan || '1'"
  >
    <div
      ref="cellRef"
      :class="[
        'relative',
        {
          'whitespace-nowrap [&>*]:truncate':
            !normalWhitespace && !overflowVisible,
          'flex items-center space-x-6': flexCell
        }
      ]"
      @mouseenter="hanldeShowTooltip"
      @mouseleave="showTooltip = false"
    >
      <slot></slot>
      <OverflowTooltip :show="tooltip && showTooltip" :tooltip="tooltip" />
    </div>
  </td>
</template>
<script setup>
import OverflowTooltip from '@/components/shared/tooltip/OverflowTooltip'
import { isTextTruncated } from '@/helpers/text'
import { ref } from 'vue'

const props = defineProps({
  colspan: String,
  normalWhitespace: {
    type: Boolean,
    default: false
  },
  overflowVisible: {
    type: Boolean,
    default: false
  },
  applyPadding: {
    type: Boolean,
    default: true
  },
  flexCell: {
    type: Boolean,
    default: false
  },
  tooltip: String
})

const cellRef = ref(null)
const showTooltip = ref(false)

function hanldeShowTooltip() {
  if (!props.tooltip) return
  if (isTextTruncated(cellRef.value)) showTooltip.value = true
}
</script>
